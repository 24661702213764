













import {Vue, Component} from "vue-property-decorator";
@Component({
    name: 'open_register_micro'
})
export default class open_register_micro extends Vue {
    created() {
        console.log()
    }
}

